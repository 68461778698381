import React, { ReactNode } from 'react'
import './layout.scss'
import { GlobalStyle } from '../../GlobalStyle'
import '../../../fonts/fonts.css';

interface Props {
  children: ReactNode
  locale: string
}

const Layout = ({ children, locale }: Props): JSX.Element => {
  return (
    <>
      <GlobalStyle />
      <main>{children}</main>
    </>
  )
}

export default Layout
